import axios, { AxiosError, AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';
import { useAuthToken } from 'src/auth/hooks/use-auth-token';
import uuidv4 from './uuidv4';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    const customResponse = {
      axiosData: {
        message: 'Service unavailable. Try again later.',
        data: {},
      },
      status: 503,
    };

    // The server is up, but the request was invalid
    if (error.response) {
      // The server responded with a known response
      // A server response is type of { message: string, data: {} }
      if (error.response.data?.message) {
        customResponse.axiosData.data = error.response.data.data;
        customResponse.axiosData.message = error.response.data.message;

        // The server responded with an unknown response
      } else {
        customResponse.axiosData.data = error.response.data;
      }

      customResponse.status = error.response.status;
    }

    return Promise.reject(customResponse);
  }
);

export default axiosInstance;

export const useAuthHeader = () => {
  const token = useAuthToken();

  if (token) {
    axiosInstance.interceptors.request.use((config: any) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }
  return token;
};

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig, boolean]) => {
  const [url, config, isReady = true] = Array.isArray(args) ? args : [args];

  try {
    if (!isReady) return null;

    const finalConfig = updateHeaders(config);

    const { data: axiosData, status } = await axiosInstance.get(url, finalConfig);

    return { axiosData, status };
  } catch (error) {
    console.error(`Error GET ${url} =`, error);
    return error;
  }
};
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export const getter = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  try {
    const finalConfig = updateHeaders(config);

    const { data: axiosData, status } = await axiosInstance.get(url, finalConfig);

    return { axiosData, status };
  } catch (error) {
    console.error(`Error GET ${url} =`, error);
    return error;
  }
};
// ----------------------------------------------------------------------

export const create = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  try {
    const finalConfig = updateHeaders(config);

    const { data: axiosData, status } = await axiosInstance.post(url, config?.data, finalConfig);
    return { axiosData, status };
  } catch (error) {
    console.error(`Error POST ${url} =`, error);
    return error;
  }
};

// ----------------------------------------------------------------------

export const update = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  try {
    const finalConfig = updateHeaders(config);

    const { data: axiosData, status } = await axiosInstance.put(url, config?.data, finalConfig);

    return { axiosData, status };
  } catch (error) {
    console.error(`Error PUT ${url} =`, error);
    return error;
  }
};

// ----------------------------------------------------------------------

export const remove = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  try {
    const finalConfig = updateHeaders(config);

    const { data: axiosData, status } = await axiosInstance.delete(url, finalConfig);

    return { axiosData, status };
  } catch (error) {
    console.error(`Error DELETE ${url} =`, error);
    return error;
  }
};

// ----------------------------------------------------------------------

const updateHeaders = (config?: AxiosRequestConfig) => ({
  ...config,
  headers: {
    ...(config?.headers || {}),
    'X-Request-ID': uuidv4(),
  },
});

// ----------------------------------------------------------------------

type PathVariables = {
  id?: string | number | null;
  publicId?: string | number | null;
  userId?: string | number | null;
  companyId?: string | number | null;
  employeeId?: string | number | null;
  customerId?: string | number | null;
  appointmentId?: string | number | null;
  availabilityId?: string | number | null;
  serviceId?: string | number | null;
};
export const endpoints = (pathVariables: PathVariables) => {
  const {
    id,
    publicId,
    userId,
    companyId,
    customerId,
    employeeId,
    appointmentId,
    availabilityId,
    serviceId,
  } = pathVariables;

  return {
    company: {
      getOne: `api/company/${id}`,
      create: `api/company`,
      updateOne: `api/company/${id}`,
      availability: {
        getOne: `api/company/${companyId}/availability/${availabilityId}`,
        getAll: `api/company/${companyId}/availability`,
        create: `api/company/${companyId}/availability`,
        updateOne: `api/company/${companyId}/availability/${availabilityId}`,
        remove: `api/company/${companyId}/availability/${availabilityId}`,
      },
      service: {
        getOne: `api/company/${companyId}/service/${serviceId}`,
        getAll: `api/company/${companyId}/service`,
        create: `api/company/${companyId}/service`,
        updateOne: `api/company/${companyId}/service/${serviceId}`,
        remove: `api/company/${companyId}/service/${serviceId}`,
      },
      customer: {
        getOne: `api/company/${companyId}/customer/${customerId}`,
        getAll: `api/company/${companyId}/customer`,
        create: `api/company/${companyId}/customer`,
        updateOne: `api/company/${companyId}/customer/${customerId}`,
        createOrUpdate: `api/public/company/${companyId}/customer`,
        remove: `api/company/${companyId}/customer/${customerId}`,
      },
      employee: {
        getOne: `api/company/${companyId}/employee/${employeeId}`,
        getAll: `api/company/${companyId}/employee`,
        create: `api/company/${companyId}/employee`,
        updateOne: `api/company/${companyId}/employee/${employeeId}`,
        remove: `api/company/${companyId}/employee/${employeeId}`,
        availability: {
          getOne: `api/company/${companyId}/employee/${employeeId}/availability/${availabilityId}`,
          getAll: `api/company/${companyId}/employee/${employeeId}/availability`,
          getTimeSlots: `api/company/${companyId}/employee/${employeeId}/time-slots`,
          create: `api/company/${companyId}/employee/${employeeId}/availability`,
          updateOne: `api/company/${companyId}/employee/${employeeId}/availability/${availabilityId}`,
          remove: `api/company/${companyId}/employee/${employeeId}/availability/${availabilityId}`,
        },
      },
    },
    appointment: {
      getOne: `api/appointment/${id}`,
      getAll: `api/appointment`,
      create: `api/appointment`,
      updateOne: `api/appointment/${id}`,
      remove: `api/appointment/${id}`,
      public: {
        create: `api/public/appointment`,
        remove: `api/public/appointment/${publicId}`,
      },
    },
    user: {
      getOne: `api/user/${id}`,
      getOneByAuth0Id: `api/user/auth0/${id}`,
      getAll: `api/user`,
      create: `api/user`,
      updateOne: `api/user/${id}`,
      linkToEmployee: `api/user/${userId}/link-to-employee/${employeeId}`,
    },
    location: {
      getOneByCompanyId: `api/location/company/${id}`,
      create: `api/location`,
      updateOne: `api/location/${id}`,
    },
    gBucket: {
      getSignedUrl: `api/bucket/signed-url`,
    },
    notification: {
      getOne: `api/notification/${id}`,
      getOneByCompanyId: `api/notification/company/${companyId}`,
      create: `api/notification`,
      updateOne: `api/notification/${id}`,
    },
  };
};
